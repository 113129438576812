<template>
  <van-nav-bar
  title="账户设置"
  left-arrow
  @click-left="onGoBack"
  />
  <van-cell-group class="group">
    <van-cell center >
      <template #title style="display:flex;flex-direction: column;width: 20%;">
        <van-image style="float:left" width="45" height="45" src="people.png" />
        <div style="position:relativ;margin-left:50px;"><div class="custom-title">{{userStore.userInfo.name}}</div>
        <van-tag type="danger">{{userStore.userInfo.phone}}</van-tag></div>
      </template>
    </van-cell>
  </van-cell-group>
  <van-cell-group style="margin-top:10px;border-radius:25px">
    <van-cell title="修改密码" is-link to='xgpassword'>
    </van-cell>
    <!--<van-cell title="变更手机号" is-link to='modifyphone'>
    </van-cell>
    <van-cell title="基本信息管理" is-link to='modifyuserprimaryinfo'>
    </van-cell>-->
    <van-cell title="地址管理" is-link >
    </van-cell>
    <van-cell title="支付设置" is-link >
    </van-cell>
    <van-cell title="安全设置" is-link >
    </van-cell>
  </van-cell-group>
  <van-cell-group style="margin-top:10px;border-radius:25px">
    <van-cell title="关于本系统" is-link to='sysinfo'>
    </van-cell>
  </van-cell-group>
  <van-button  round type="success" class='bottombutton' block @click="login_out">退出登录</van-button>
</template>

<script>
import { ref,reactive,getCurrentInstance,onMounted, watch} from 'vue';
import { useUserStore } from '@/store/user.ts'
import { useRouter } from 'vue-router'

const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
  },

  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    onMounted(()=>{

    });
    

    let {proxy}=getCurrentInstance();
    let tokens={'Authorization':userStore.userInfo.token}
    const login_out=()=>{
      proxy.$axios.get(process.env.VUE_APP_API_PORT_BASE_URL+'/user/logout','',{headers:tokens})
      userStore.setUserInfo({})
      router.push('/login');
    }

    return {
      userStore,
      router,
      login_out
    };
  },


};
</script>

<style lang="less">
.group{
  background: aquamarine;
}

.edit-icon {
    font-size: 16px;
    line-height: inherit;
  }
.bottombutton{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
